
import $ from 'jquery'


import "@iconfu/svg-inject"


import 'slick-carousel'


// import '../node_modules/aos/dist/aos.css'; // You can also use <link> for styles
import AOS from 'aos';


import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js"


import { Datepicker } from 'vanillajs-datepicker';
import it from '../node_modules/vanillajs-datepicker/js/i18n/locales/it.js';
Object.assign(Datepicker.locales, it);


const simpleParallax = require('simple-parallax-js')


import './style.scss'


/* Given a start date, end date and day index, return
** an array of dates between the two dates for the
** given day inclusive
** @param {Date} start - date to start from
** @param {Date} end - date to end on
** @param {string} dayName - name of day
** @returns {Array} array of Dates
*/
function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}
function formatDate(date) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('/')
}
function getDaysBetweenDates(start, end, dayIndex) {
  var result = []
  // var days = {sun:0,mon:1,tue:2,wed:3,thu:4,fri:5,sat:6}
  // var day = days[dayName.toLowerCase().substr(0,3)]
  // Copy start date
  var current = new Date(start)
  // Shift to next of required days
  current.setDate(current.getDate() + (dayIndex - current.getDay() + 7) % 7)
  // While less than end date, add dates to result array
  while (current < end) {
    var date2clean = new Date(+current)
    var dateFormatted = formatDate(date2clean)
    result.push(dateFormatted)
    current.setDate(current.getDate() + 7)
  }
  return result
}


// Tutti i giorni tra due date fornite
function getAllDaysBeetweenDates(start, end) {
  for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
    arr.push(formatDate(new Date(dt)))
  }
  return arr
}


// UNIQUE ARRAY
function uniq(a) {
  var seen = {}
  return a.filter(function(item) {
    return seen.hasOwnProperty(item) ? false : (seen[item] = true)
  })
}


// HOME VIDEO AUTOPLAY
if ( document.getElementById('video-top') != null ) {
  document.getElementById('video-top').play()
}


// NABVAR MENU TOGGLE
const menu = document.querySelector('#menu-toggler')
const body = document.querySelector('body')
const closemenu = document.querySelector('#close-menu')
const menuItems = document.querySelector('#overlay')
const menuContainer = document.querySelector('.menu-container')
function toggleMenu(e) {
  menuItems.classList.toggle('open')
  body.classList.toggle('h-overflow')
  menuContainer.classList.toggle('full-menu')
  e.preventDefault()
}
closemenu.addEventListener('click', toggleMenu, false)
menu.addEventListener('click', toggleMenu, false)


// FANCYBOX PRODUCT TABLE
Fancybox.bind(".open-product-table", {
  mainClass: "product-table-modal"
})


// ADD CLASS NAV IF SCROLL
let scrollpos = window.scrollY
const header = document.querySelector("nav")
const header_height = header.offsetHeight
const add_class_on_scroll = () => header.classList.add("scrolled")
const remove_class_on_scroll = () => header.classList.remove("scrolled")
window.addEventListener('scroll', function() {
  scrollpos = window.scrollY
  if (scrollpos >= header_height) {
    add_class_on_scroll()
  }
  else {
    remove_class_on_scroll()
  }
})
window.addEventListener("load", function() {
  if (scrollpos >= header_height) {
    add_class_on_scroll()
  }
  else {
    remove_class_on_scroll()
  }
})


// PARALLAX INIT
var parallaxObj = document.getElementsByClassName('parallax-img')
new simpleParallax(parallaxObj, {
  // delay: .6,
	// transition: 'cubic-bezier(0,0,0,1)'
})


// OFFCANVAS MENU
// function offcanvasMuscle() {
//   var triggers = document.getElementsByClassName('navbar-toggler')
//   for (var i = 0; i < triggers.length; i++) {
//     triggers[i].addEventListener("click", function() {
//       var targetMenu = this.getAttribute('offcanvas-menu')
//       console.log(targetMenu)
//       toggleOffcanvasMuscleMenu(targetMenu)
//     })
//   }
// }
// function toggleOffcanvasMuscleMenu(menuId) {
//   var myMenu = document.getElementById(menuId)
//   var triggers = document.getElementsByClassName('navbar-toggler')[0]
//   var triggersMob = document.getElementsByClassName('navbar-toggler')[1]
//   triggers.classList.toggle('expa')
//   triggersMob.classList.toggle('expa')
//
//   myMenu.classList.toggle('open')
//   // var siteWrap = document.getElementsByClassName('site-wrap')[0]
//   // siteWrap.classList.toggle('open')
//   // if (myMenu.classList.contains('left'))
//   // siteWrap.classList.toggle('left')
//   // if (myMenu.classList.contains('right'))
//   // siteWrap.classList.toggle('right')
// }
// offcanvasMuscle()


window.addEventListener("load", function() {
	// TABS SCRIPT // store tabs variable
	// var myTabs = document.querySelectorAll("ul.nav-tabs > li")
  // function myTabClicks(tabClickEvent) {
	// 	for (var i = 0; i < myTabs.length; i++) {
	// 		myTabs[i].classList.remove("active")
	// 	}
	// 	var clickedTab = tabClickEvent.currentTarget
	// 	clickedTab.classList.add("active")
	// 	tabClickEvent.preventDefault()
	// 	var myContentPanes = document.querySelectorAll(".tab-pane")
  //   console.log(myContentPanes);
	// 	for (var i = 0; i < myContentPanes.length; i++) {
	// 		myContentPanes[i].classList.remove("active")
	// 	}
	// 	var anchorReference = tabClickEvent.target
	// 	var activePaneId = anchorReference.getAttribute("href")
	// 	var activePane = document.querySelector(activePaneId)
	// 	activePane.classList.add("active")
	// }
	// for (var i = 0; i < myTabs.length; i++) {
	// 	myTabs[i].addEventListener("click", myTabClicks)
	// }
})


// JQUERY DEP
$(document).ready( function() {


  var ap = true


  // GOTOP
  $("#gotop").click(function() {
    $("html, body").animate({ scrollTop: 0 }, "slow")
    return false
  })


  // IMG TO SVG
  SVGInject(document.querySelectorAll("img.svg"))
  SVGInject(document.querySelectorAll("img.svg-foo"))
  SVGInject(document.querySelector(".cart img.menu-image-title-hide"))
  SVGInject(document.querySelector(".account img.menu-image-title-hide"))
  SVGInject(document.querySelector(".home img.menu-image-title-hide"))
  // if (document.querySelector(".xoo-wsc-bki img"))
  //   SVGInject(document.querySelector(".xoo-wsc-bki img"))


  // ANIMATION CIRCLE DECO CLASS
  $(".circle-deco").not(".nav-history").hover(
    function () {
      $(this).find("svg").removeClass('dash-out').addClass('dash-in')
    },
    function () {
      $(this).find("svg").removeClass('dash-in').addClass('dash-out')
    }
  )


  // SCROLL NEXT SECTION
  var $scrollSection = $('section')
  var $scrollTrigger = $('.next-block')
  var nextSection = 1
  $scrollTrigger.on('click', function() {
    $('html, body').animate({
      scrollTop: ($($scrollSection[nextSection]).offset().top) -80
    }, 1000)
  })


  // SLIDER BLOCK
  $('.slider-block .slider-img').slick({
    autoplay: ap,
    autoplaySpeed: 7000,
    speed: 800,
    focusOnSelect: true,
    infinite: true,
    arrows: true,
    variableWidth: false,
    // onInit: function () {
    //   jQuery(window).resize()
    //   console.log('slickcaroseal locded')
    // },
    centerMode: false,
    prevArrow: $('body').find('.slider-block .slick-left'),
    nextArrow: $('body').find('.slider-block .slick-right')
  })


  // LOAD HISTORY SLIDER
  var $elch = $('.carousel-history')
  $elch.on('init', function(event, slick){
    // console.log("initialized")
  })
  $elch.slick({
    autoplay: ap,
    autoplaySpeed: 7000,
    speed: 400,
    infinite: true,
    arrows: false,
    fade: true,
    cssEase: 'linear',
    adaptiveHeight: false
    // prevArrow: $('body').find('.carousel-block-imgs .slick-left'),
    // nextArrow: $('body').find('.carousel-block-imgs .slick-right'),
  })
  $('.history-block .nav-wrapper a').on("click", function(){
    var index = $(this).data("index")
    // $('.history-block .nav-wrapper a').removeClass("active")
    // $(this).addClass("active")
    $elch.slick('slickGoTo', index)
  })
  // var beforeChange = 0
  // $elch.on('beforeChange', function (event, slick, currentSlide) {
  //   // console.log('beforeChange, currentSlide: ', currentSlide)
  //   beforeChange = currentSlide
  // })
  $elch.on('afterChange', function (event, slick, currentSlide) {
    // console.clear()
    // console.log(currentSlide)
    $('.history-block .nav-wrapper a').removeClass("active")
    $('.history-block .nav-wrapper a[data-index="'+(currentSlide)+'"]').addClass("active")
    $('.history-block .nav-wrapper a').each(function(i){
      if ($(this).children().hasClass("dash-out")) {
        $(this).children().removeClass("dash-out")
        $(this).children().addClass("dash-in")
      }
      if ($(this).hasClass("active")) {
        $(this).children().addClass("dash-out")
        $(this).children().removeClass("dash-in")
      }
    })
    // console.log('afterChange, currentSlide: ', currentSlide)
    // if (currentSlide != beforeChange) {
    //   $('.history-block .nav-wrapper a').not('[data-index="'+(currentSlide)+'"]').children().toggleClass("dash-out")
    //   $('.history-block .nav-wrapper a').not('[data-index="'+(currentSlide)+'"]').children().toggleClass("dash-in")
    //   $('.history-block .nav-wrapper a[data-index="'+(currentSlide)+'"] svg').toggleClass("dash-out")
    //   $('.history-block .nav-wrapper a[data-index="'+(currentSlide)+'"] svg').toggleClass("dash-in")
    // }
  })


  // LOAD PARTIAL SLIDER TEXT
  var $el = $('.carousel-partial-img')
  $el.on('init', function(event, slick){
    $el.find(".slick-slide").not(".slick-cloned").find("a").attr("data-fancybox","gallery-c-b")
    // console.log()
  })
  $el.slick({
    autoplay: ap,
    autoplaySpeed: 7000,
    speed: 800,
    infinite: true,
    arrows: true,
    prevArrow: $('body').find('.carousel-block-imgs .slick-left'),
    nextArrow: $('body').find('.carousel-block-imgs .slick-right'),
    responsive: [
      {
        breakpoint: 1200, // tablet breakpoint
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 992, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
          // adaptiveHeight: true
        }
      },
      {
        breakpoint: 576, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
          // adaptiveHeight: true
        }
      }
    ]
  })


  // CAROUSEL PRODUCTS
  $('.carousel-products').slick({
    slidesToShow: 4,
    autoplay: ap,
    autoplaySpeed: 7000,
    speed: 800,
    infinite: true,
    arrows: true,
    prevArrow: $('body').find('.intro-shop .slick-left'),
    nextArrow: $('body').find('.intro-shop .slick-right'),
    responsive: [
      {
        breakpoint: 1440, // tablet breakpoint
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 992, // mobile breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
          // adaptiveHeight: true
        }
      },
      {
        breakpoint: 767, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
          // adaptiveHeight: true
        }
      },
      {
        breakpoint: 576, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
          // adaptiveHeight: true
        }
      }
    ]
  })


  // CAROUSEL RECIPES
  $('.carousel-recipes').slick({
    slidesToShow: 4,
    autoplay: ap,
    autoplaySpeed: 7000,
    speed: 800,
    infinite: true,
    arrows: true,
    prevArrow: $('body').find('.recipes-block .slick-left'),
    nextArrow: $('body').find('.recipes-block .slick-right'),
    responsive: [
      {
        breakpoint: 980, // tablet breakpoint
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 576, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true
        }
      }
    ]
  })


  // SLIDER SQR
  $('.slider-img-sqr').slick({
    autoplay: ap,
    autoplaySpeed: 7000,
    speed: 800,
    infinite: true,
    arrows: true,
    prevArrow: $('body').find('.final-block .slick-left'),
    nextArrow: $('body').find('.final-block .slick-right')
  })


  // DATE PICKER TOURS
  if (document.querySelector('input[name="data"]')) {
    const elem = document.querySelector('input[name="data"]')

    var today = new Date()
    var tomorrow = new Date(today)
    var maxDate = new Date(tomorrow)

    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    today = dd + '/' + mm + '/' + yyyy

    tomorrow.setDate(tomorrow.getDate() + 1)
    var tomorrowDate = tomorrow
    dd = String(tomorrow.getDate()).padStart(2, '0')
    mm = String(tomorrow.getMonth() + 1).padStart(2, '0') //January is 0!
    yyyy = tomorrow.getFullYear()
    tomorrow = dd + '/' + mm + '/' + yyyy

    maxDate.setDate(maxDate.getDate() + 90)
    var maxDateDate = maxDate
    dd = String(maxDate.getDate()).padStart(2, '0')
    mm = String(maxDate.getMonth() + 1).padStart(2, '0') //January is 0!
    yyyy = maxDate.getFullYear()
    maxDate = dd + '/' + mm + '/' + yyyy

    var daysOfWeekFromCalc = []
    $.each(disDay, function( index, value ) {
      daysOfWeekFromCalc.push( getDaysBetweenDates(tomorrowDate, maxDateDate, value) )
    })
    var finalDaysOfWeekFromCalc = []
    for(var i = 0; i < daysOfWeekFromCalc.length; i++) {
      for(var y = 0; y < daysOfWeekFromCalc[i].length; y++) {
        // console.log( daysOfWeekFromCalc[i][y] )
        finalDaysOfWeekFromCalc.push( daysOfWeekFromCalc[i][y] )
      }
    }
    finalDaysOfWeekFromCalc.sort( function(a, b) {
        var aa = a.split('/').reverse().join(),
            bb = b.split('/').reverse().join()
        return aa < bb ? -1 : (aa > bb ? 1 : 0)
    })

    var allDaysBeetweenDates = getAllDaysBeetweenDates(tomorrowDate, maxDateDate)

    // console.log(finalDaysOfWeekFromCalc)
    // console.log(allDaysBeetweenDates)

    var diff = allDaysBeetweenDates.filter(x => !finalDaysOfWeekFromCalc.includes(x))
    var lastElement = diff.slice(-1)[0]
    finalDaysOfWeekFromCalc.push(lastElement)
    // console.log(lastElement)

    finalDaysOfWeekFromCalc = disSpecDay.concat(finalDaysOfWeekFromCalc)
    finalDaysOfWeekFromCalc = uniq(finalDaysOfWeekFromCalc)

    const datepicker = new Datepicker(elem, {
      language: "it",
      orientation: "bottom",
      // daysOfWeekDisabled: disDay,
      minDate: tomorrow,
      maxDate: maxDate,
      datesDisabled: finalDaysOfWeekFromCalc
    })





  }


  // FORM URL AND PAGENAME
  $(".wpcf7-form #url").attr("value", document.URL)
  $(".wpcf7-form #nome_pagina").attr("value", document.title)
  document.addEventListener( 'wpcf7submit', function( event ) {
    $(".wpcf7-form #url").val(document.URL)
    $(".wpcf7-form #nome_pagina").val(document.title)
  }, false )


  // VIDEOs MANAGMENT
  $(".video-wrapper button").on("click",function(){
    // var vidObj = $(this).parent().find("video")
    // if (vidObj.parent().hasClass("stopped")) {
    //   vidObj.get(0).play()
    //   $(this).find("i.play").hide()
    //   $(this).find("i.pause").show()
    // } else {
    //   vidObj.get(0).pause()
    //   $(this).find("i.play").show()
    //   $(this).find("i.pause").hide()
    // }
    // vidObj.parent().toggleClass("stopped")
  })
  $(".video-wrapper .full-screen").on("click",function(){
    // var vidObj = $(this).parent().find("video")
    // $(this).parent().find("i.play").show()
    // $(this).parent().find("i.pause").hide()
    // vidObj.parent().addClass("stopped")
    // vidObj.get(0).pause()
  })
  Fancybox.bind("[data-fancybox-plyr]", {
    on: {
      reveal: (fancybox, slide) => {
        // if (typeof Plyr === undefined) {
        //   return;
        // }
        let $el;
        if (slide.type === "html5video") {
          $el = slide.$content.querySelector("video");
        } else if (slide.type === "video") {
          $el = document.createElement("div");
          $el.classList.add("plyr__video-embed");
          $el.appendChild(slide.$iframe);
          slide.$content.appendChild($el);
        }
        if ($el) {
          // slide.player = new Plyr($el);
        }
      },
      closing: (fancybox, slide) => {

      },
      "Carousel.unselectSlide": (fancybox, carousel, slide) => {
        if (slide.player) {
          slide.player.pause();
        }
      },
      "Carousel.selectSlide": (fancybox, carousel, slide) => {
        if (slide.player) {
          slide.player.play();
        }
      },
    },
  });


  // QUICK ADD TO CART
  $( "#variations form" ).submit(function( event ) {
    setTimeout(function(){ Fancybox.close() }, 1000)
  })


  // LOAD SLIDER FHEIGHT
  // $('.slick-top').slick({
  //   autoplay: ap,
  //   autoplaySpeed: 7000,
  //   speed: 500,
  //   fade: true,
  //   dots: true,
  //   arrows: false
  // })
  // Run when slides change
  // $('.slick-top').on('afterChange', function(event, slick, currentSlide) {
  //   // Hide/show captions
  //   $('.slick-top').find("h3").removeClass("animated fadeInUp")
  //   $(slick.$slides.get(currentSlide)).find("h3").addClass("animated fadeInUp")
  // })


  // LOAD PARTIAL SLIDER TEXT
  // $('.slider-text').slick({
  //   autoplay: ap,
  //   autoplaySpeed: 7000,
  //   speed: 800,
  //   infinite: true,
  //   arrows: true,
  //   prevArrow: $('body').find('.slider-text-wrapper .slick-left'),
  //   nextArrow: $('body').find('.slider-text-wrapper .slick-right'),
  // })


  // HEIGHT MASONRY GRID FROM ATTRIBUTE
  // var heightAttr = $(".index-masonry-gallery #wrapper").data("height")
  // $(".index-masonry-gallery #wrapper").css("max-height", heightAttr)


  // MANAGE ATM PROTECTED ON PRODUCT PAGE
  var already_checked = false
  var already_unchecked = false
  $(".wc-pao-addons-container input[type='checkbox']").on("click", function(){
    if ($(this).is(":checked")) {
      if (!already_checked) {
        var add_price = $(this).data("price")
        var r_price = $(".single-product-details .woocommerce-Price-amount bdi").text()
        $(".single-product-details .woocommerce-Price-amount bdi").attr("data-s_price",r_price)
        r_price = parseFloat(r_price.replace("€","").replace(",",".").trim())
        var final_price = ( r_price + add_price ).toFixed(2)
        final_price = final_price.toString()
        final_price = final_price.replace(".",",")
        $(".single-product-details .woocommerce-Price-amount bdi").text(final_price + " €")
        already_checked = true
        already_unchecked = false
      }
    } else {
      if (!already_unchecked) {
        var add_price = $(this).data("price")
        var r_price = $(".single-product-details .woocommerce-Price-amount bdi").text()
        r_price = parseFloat(r_price.replace("€","").replace(",",".").trim())
        var final_price = ( r_price - add_price ).toFixed(2)
        final_price = final_price.toString()
        final_price = final_price.replace(".",",")
        $(".single-product-details .woocommerce-Price-amount bdi").text(final_price + " €")
        already_unchecked = true
        already_checked = false
      }
    }
  })
  $(".woovr-variation-info .woovr-variation-name").on("click", function(){
    if ($(".wc-pao-addons-container input[type='checkbox']").prop( "checked") == true ) {
      $(".wc-pao-addons-container input[type='checkbox']").prop( "checked", false )
    }
    $(".single-product-details .woocommerce-Price-amount bdi").text($(".single-product-details .woocommerce-Price-amount bdi").data("s_price"))
    already_checked = false
    already_unchecked = false
  })


  // MANAGE SWITCH IMAGE VARIATIONS
  var variationsJson = $(".variations_form").data("product_variations")
  $(".woovr-variation.woovr-variation-radio").on("click", function(){
    var varId = $(this).data("id")
    $(".product-image.variation").fadeOut(0)
    $(".product-image.variation").each(function(i){
      if ( $(this).data("vid") == varId ) {
        $(this).fadeIn(200)
      }
    })
  })


  // TRICK MANAGE ACTIVE ON MENU MOBILE SHOP
  $("#overlay .overlay-menu").find(".current-product-ancestor").parent().parent().addClass("current-menu-ancestor")
  $("#overlay .overlay-menu").find(".current-menu-item").parent().parent().addClass("current-menu-ancestor")
  $("#overlay .overlay-menu").find(".current-ricette-ancestor").parent().parent().addClass("current-menu-ancestor")



  AOS.init({
    delay: 200
  })
  window.addEventListener('load', AOS.refresh)


})
